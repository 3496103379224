@import "variables.module";

.container {
  height: 100%;
  min-height: calc(100vh - 52px);
  max-width: $width-max;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &.hasExample {
    min-height: calc(100vh - 138px);
  }
}

.description {
  width: 268px;
  display: flex;
  flex-grow: 1;
  align-items: center;

  > span {
    color: $color-white;
    font-weight: 700;
    font-size: 32px;
    letter-spacing: -0.32px;
  }
}

.section {
  width: 100%;
  max-width: $width-max;
  padding: 32px 0;
  background: $color-white;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup {
  > span {
    font-size: 14px;
  }

  &.hasExample {
    padding-top: 0;
  }
}

.inviteSignup {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 16px;
}

.error {
  width: 250px;
  margin-bottom: 16px;
  color: $color-red-lighter;
  font-weight: 500;
  font-size: 13px;
  line-height: 110%;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.terms {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 13px;

  a {
    color: #aaaaaa;
  }
}

.signupLink {
  margin-top: 32px;
  a {
    color: $color-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    text-decoration-line: underline;
  }
}
