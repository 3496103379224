@import "variables.module";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageIcon {
  position: absolute;
  top: 15px;
  left: 15px;
}

.inputItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
}

.inputItem {
  //background-color: red;
  width: 300px;
  //height: 38px;
  //margin-top: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  padding: 10px 0;
  //background-color: red;

  > input {
    width: 300px;
    height: 21px;
    line-height: 21px;
    padding: 15px 0;

    &::placeholder {
      font-size: 13px;
      color: $color-gray;
      opacity: 1;
      text-align: center;
    }
  }

  &Full {
    width: 375px;
    text-align: left;
    padding: 0 10px 15px;

    > input {
      width: 375px;

      &::placeholder {
        text-align: left;
      }
    }
  }
}

.inputError {
  font-size: 13px;
  line-height: 18px;
  color: $color-red-lighter;
}

.inputFullName {
  position: relative;

  .required {
    color: $color-red-lighter;
    position: absolute;
    top: 20px;
    right: 85px;
  }
}

.inputUserName {
  width: 100%;
  height: 51px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &Error {
    height: 73px;
    padding-bottom: 31px;
  }

  .leadingText {
    &:before {
      content: "@";
      font-size: 16px;
      color: $color-gray;
      margin-right: 5px;
    }

    &:after {
      content: "";
      margin-right: 13px;
    }

    > label {
      opacity: 0;
    }
  }

  > input {
    position: absolute;
    left: 0;
    font-size: 14px;
  }

  .required {
    color: $color-red-lighter;
    position: absolute;
    top: 20px;
    right: 85px;
  }

  .inputError {
    position: absolute;
    top: 40px;
    width: 340px;
  }
}

.inputName {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.inputBio {
  width: 100%;
  height: 54px;
  font-size: 14px;
  line-height: 18px;
}

.bgImages {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bgImagesFull {
  width: 100%;
  border: none;
  padding: 0;
}

.bgImageSelected {
  &:after {
    background-color: $color-red;
  }
}

.editImage {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  .avatarMain {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: white;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 0 2px #fff;

    img {
      border-radius: 50%;
    }

    .imageIcon {
      position: absolute;
      top: 35px;
      left: 35px;
    }
  }
}

.invite {
  &Header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    > h3 {
      font-size: 18px;
      font-weight: 700;
      text-align: center;

      > span {
        display: block;
      }
    }

    > span {
      margin-top: 24px;
      color: $color-gray-darker;
      font-size: 13px;
    }

    p {
      padding: 0 20px;
      margin-top: 24px;
      color: $color-gray-darker;

      > a {
        color: $color-black;
        font-weight: 700;
      }
    }
  }

  &Qr {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > span {
      margin-top: 10px;
      color: $color-gray-darker;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 340px;
    }
  }

  &Footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 56px;

    > a {
      margin-top: 24px;
    }
  }
}

.write {
  padding: 20px 18px;
  background-color: $color-white;

  &Header {
    width: 100%;
  }

  &Input {
    padding-top: 20px;
    width: 100%;
    //height: 100%;
    //flex-grow: 1;
    //padding: 0 18px;
    > textarea {
      width: 100%;
      min-height: 270px;
      //max-height: 400px;

      &::placeholder {
        font-size: 13px;
        color: $color-gray;
      }
    }

    .exampleText {
      width: 100%;
      max-width: $width-max;
      height: 200px;
      padding-left: 20px;
      position: absolute;
      top: 218px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 13px;
      color: $color-gray;
    }

    .dscribeTextarea {
      width: 100%;
      height: 200px;
      position: relative;

      &.hasExample {
        color: $color-gray;
        line-height: 21px;
      }
    }
  }

  &Footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0;

    > p {
      text-align: left;
      font-size: 12px;
      color: $color-red;
    }

    > button {
      flex-shrink: 1;
    }
  }
}

.completed {
  background-color: $color-white;
  padding: 56px 20px 18px;
  height: 100%;
  min-height: calc(100vh - 138px);

  h2 {
    font-size: 18px;
    font-weight: 700;
    color: $color-black;
    margin: 16px 0;
  }

  p {
    width: 230px;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-line;
  }

  > p {
    margin-bottom: 66px;
  }

  h3 {
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
  }

  .copyArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-gray-lighter-200;
    border-radius: 5px;
    padding: 24px 12px 12px;

    > .copyButton {
      align-self: flex-end;
      display: flex;
      justify-items: center;

      > span {
        font-size: 12px;
        font-weight: 700;
        margin-right: 4px;
      }
    }
  }

  .doneSection {
    margin-top: 40px;
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
  }
}
